.modal-overlay {
    background: rgba(0, 0, 0, 0.6);

    position: fixed;
    top: 0;
    left: 0;
    
    height: 0;
    width: 100%;

    opacity: 0;
    transition: opacity 0.3s ease;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: map-get($z-indexes, "modal-overlay");

    &--active {
        height: 100%;

        opacity: 1;
    }
}

.modal {
    $self: &;

    z-index: map-get($z-indexes, "modal");
    background-color: #fff;

    padding: 30px 40px;

    background-color: $color__background--grey;
    border: 2px solid $color__border--dark;

    flex: 0 0 90%;
    @include breakpoint("small+") {
        flex: 0 0 55%;
    }

    &__header {
        font-size: $font-size__massive--desktop;
        width: 60%;

        @include breakpoint("small-") {
            font-size: $font-size__massive--mobile;
            line-height: 0.9;
        }
    }

    &--max-width {
        & > #{ $self } {
            &__error-header {
                width: 100%;
            }
        } 
    }
}

.modal {
    @include theme() { 
        background-color: getThemeValue("color__background--grey");
        border: 2px solid getThemeValue("color__border--dark");
    }
}