$description-z-indexes: (
    description__copy: 1,
    description__button: 2,
);

.description {
    padding: 23px 40px 30px;

    &--mobile {
        @include breakpoint("small+") {
            display: none;
        }
    }

    &--desktop {
        @include breakpoint("small-") {
            display: none;
        }
    }

    &__header-section {
        display: flex;
        flex-direction: row;

        &--negative-bottom {
            margin-bottom: -10px;
        }
    }

    &__header {
        margin: 0 0 5px;

        font-size: $font-size__header--desktop;
        line-height: 1.2;
    }

    &__sub-header {
        margin: 0;

        font-size: $font-size__sub-header;
        line-height: 1.2;
    }

    &__detail {
        margin: 10px 0;
        
        font-size: $font-size__body;
        line-height: 1.2;
    }

    &__profile-pic {
        display: inline-block;
        background-color: #fff;
        padding: 5px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        
        margin-left: auto;

        & > svg {
            width: 50px;
            color: $color__brand--light;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__section {
        padding-bottom: 30px;
    }

    &__copy {
        z-index: map-get($description-z-indexes, "description__copy");

        display: block;
        margin-top: 12px;

        font-size: $font-size__body;
        font-family: $font-family__secondary;
        line-height: 1.4444444444;

        @include breakpoint("xlarge+") {
            width: 75%;
        }

        & > p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__button {
        z-index: map-get($description-z-indexes, "description__button");

        font-size: $font-size__body;
        font-family: $font-family__primary;

        margin-top: 20px;
        padding: 0;
        
        background: transparent;
        border: none;
        
        &:hover {
            cursor: pointer;
        }
    }
}

// THEME
.description {
    $self: &;
    
    @include theme() {        
        @include breakpoint("small-") {
            background-color: getThemeValue("color__background--dark");
        }

        #{ $self } {

            &__detail {
                color: getThemeValue("color__copy--medium");
            }
    
            &__profile-pic {
                border: 1px solid getThemeValue("color__grey");
            }
    
            &__section {
                @include breakpoint("medium+") {
                    border-bottom: 1px solid getThemeValue("color__grey");
                }
            }

            &__button {
                color: getThemeValue("color__copy--medium");
            }
        }
        
    }
}