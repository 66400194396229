$facebook-blue: rgb(59, 89, 152);
$twitter-blue: rgb(0, 172, 237);

.social {
    padding: 0px 40px 65px;

    &__copy {
        line-height: 1.2;
        font-size: $font-size__sub-header;
        font-weight: bold;

        margin-bottom: 25px;
        max-width: 380px;
    }

    &__share-section {
        display: flex;
        flex-direction: row;

        @include breakpoint("xlarge+") {
            display: block;
        }
    }

    &__button-wrapper {
        flex: 0 0 60px;

        display: flex;
        flex-direction: column;
    
        @include breakpoint("xlarge+") {
            flex-direction: row;
            margin-left: -10px;
        }
    }

    &__button {
        color: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;        

        border: none;

        font-size: $font-size__button !important;
        font-weight: bold !important;

        margin: 5px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--facebook {
            background-color: $facebook-blue !important;
        }

        &--twitter {
            background-color: $twitter-blue !important;
        }

        &--instagram {
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

            &:hover,
            &:active,
            &:visited {
                color: $color__contrast !important;
            }
        }

        @include breakpoint("xlarge+") {
            min-width: 110px;
            margin: 0 10px;
            width: auto;
            padding: 0 30px !important;
        }
    }

    &__button-icon {
        display: inline-block;
        margin-left: 10px;
        width: 35px;
        height: 35px;

        &--instagram {
            margin-left: 12px;
            fill: $color__contrast;
            width: 15px;
        }
    }

    &__location {
        flex: 1;
        padding: 0 30px 0 0;
        max-width: 400px;

    }

    &__location-description {
        & > p {
            &:first-child {
                font-weight: bold;
            }
        }
    }

    &__location-image {
        width: 100%;        
    }

    &__location-image-credit {
        font-family: $font-family__primary;
        font-weight: normal;
    }
}

// THEME
.social {
    $self: &;

    @include theme() {
        color: getThemeValue("color__contrast");

        #{ $self } {
            &__copy {
                color: getThemeValue("color__copy--dark");
            }

            &__location-description {
                color: getThemeValue("color__copy--dark");
            }

            &__location-image-credit {
                color: getThemeValue("color__copy--light");
            }
        }
    }
}