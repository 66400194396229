.stream {
  &__content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    @include breakpoint('medium+') {
      flex-direction: row;
      overflow-y: hidden;
      height: calc(100vh - 100px);
    }

    &--hidden {
      display: none;
    }
  }

  &__video {
    flex: 1;

    -ms-overflow-style: none;
    scrollbar-width: none;

    @include breakpoint('medium+') {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__chat {
    flex: 1 1;
    height: calc(100vh - 100px);

    @include breakpoint('medium+') {
      flex: 0 0 360px;
      max-width: 360px;
    }
  }

  &__error-detail {
    font-family: $font-family__secondary;
    font-size: $font-size__sub-header;

    width: 75%;
  }

  &__user-input-form {
    display: flex;
    flex-direction: column;

    & > input {
      margin: 10px 0;
    }
  }

  &__user-avatar-selector {
    background-color: transparent;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 10px 0 20px;
    max-height: 30vh;
    overflow-y: auto;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__user-avatar-button {
    background-color: transparent;
    border: none;

    display: flex;
    flex-direction: column;
    flex: 0 0 100px;

    font-size: $font-size__body;
    font-weight: 400;

    cursor: pointer;

    text-align: center;

    & > span {
      width: 100%;
    }

    & > div {
      margin: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      width: 80px;
      height: 80px;

      & > img {
        width: 100%;
      }
    }
  }

  &__description {
    position: relative;
  }

  &__user-button {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 80px;
    width: 80px;
    z-index: map-get($z-indexes, 'button');
    padding: 0;
    border-radius: 50%;

    text-transform: uppercase;
    letter-spacing: 1.1px;

    -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);

    transition: transform 0.4s, background-color 0.4s;

    background-color: $color__admin-blue;
    border-color: $color__admin-blue;

    &:hover {
      transform: translate3d(-1px, -1px, 0) scale(1.02);

      background-color: darken($color__admin-blue, 10);
    }
  }

  &__user-input-error {
    text-align: center;
  }

  &__captions {
    &-wrapper {
      margin: 5px;
      display: flex;
      flex-direction: row-reverse;
      border: 1px;

      &--active {
        height: 100px;
        border: 1px solid;
      }
    }

    &-button {
      margin: 5px;
      height: fit-content;
      width: 225px;
      font-family: $font-family__primary;
      font-weight: 700;
      padding: 10px;
      line-height: unset;
    }

    &-text {
      margin: 10px 25px;
      text-align: left;
      width: 100%;
    }
  }
}

// THEME
.stream {
  $self: &;

  transition: color 0.4s, background-color 0.4s;

  @include theme() {
    color: getThemeValue('color__copy');
    background-color: getThemeValue('color__background--light');

    .btn-barnes {
      color: getThemeValue('color__background--light');
      background-color: getThemeValue('color__brand--medium');
      border-color: getThemeValue('color__brand--medium');

      &:hover {
        background-color: darken(
          getThemeValue('color__brand--medium'),
          10
        );
      }

      &.btn-secondary {
        color: getThemeValue('color__copy--dark');
        background-color: getThemeValue('color__button--secondary');
        border-color: getThemeValue('color__button--secondary');

        &:hover {
          background-color: darken(
            getThemeValue('color__button--secondary'),
            10
          );
        }
      }
    }

    #{ $self } {
      &__input {
        color: getThemeValue('color__copy');
        background-color: getThemeValue('color__background--light');
        border: 1px solid getThemeValue('color__button--border');
      }

      &__user-avatar-selector {
        background-color: getThemeValue('color__background--light');
        border: 1px solid getThemeValue('color__button--border');
      }

      &__user-avatar-button {
        color: getThemeValue('color__copy');
        border: 1px solid getThemeValue('color__background--light');

        &--selected {
          border: 1px solid getThemeValue('color__button--border');
        }
      }

      &__user-input-error {
        color: getThemeValue('color__brand--light');
      }

      &__user-button {
        border: 1px solid $color__admin-blue;
        background-color: $color__admin-blue;
        color: #fff;

        &:hover {
          background-color: darken($color__admin-blue, 10);
        }
      }

      &__captions-wrapper--active {
        border: 1px solid getThemeValue('color__copy--dark');
      }

      &__captions-text {
        color: getTheme('color__copy');
      }
    }
  }
}