// # TODO => Replace this with appropriate styles.
// as of right now this is just a copy of the pollma.pe skins

$color__border: #dcdcdc;
$color__border--dark: #b4b4b4;
$color__border--light: #e4e4e4;
$color__copy--dark: #282828;
$color__copy--light: #8c8c8c;
$color__copy--medium: #6e6e6e;
$color__background: #ffffff;
$color__background--footer: #f7f7f7;
$color__border--dark: #b4b4b4;
$color__border--light: #e4e4e4;
$color__red--barnes: #d64220;
$color__red--barnes-dark: #b3371b;

#content {
  border: 1px solid $color__border--dark;
}

.poll-results-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  padding-left: 40px;
}

.options {
  min-height: 400px;
  padding: 0 20px;
}

.custom {
  font-family: Calibre, sans-serif;
  font-size: 19px;
  width: 480px;

  color: $color__copy--dark;
  background-color: $color__background;

  transition: opacity 0.5s;

  &.removing {
    opacity: 0;
  }
  
  .active {
    opacity: 1;
  }

  .metaInfo {
    background-color: $color__background--footer;

    padding: 10px;
    display: flex;

    opacity: 1 !important;

    > * {
      text-align: left;
      text-transform: uppercase;
      color: $color__copy--medium;
      font-size: 15px;
      letter-spacing: .04em;
      line-height: 1.5625;
    }
  }

  .total,
  .duration {
    background-color: $color__background--footer;
    width: 50%;
    padding: 10px;
    margin: 0;
    
    line-height: 1.3;

    strong {
      color: $color__copy--dark;
    }
  }

  .finshed,
  .removing {
    .options {
      li {
        opacity: 0.3;

        .highest {
          color: $color__red--barnes;
        }

        .help {
          height: 0;
        }
      }
    }
  }

  .active {
    .options {
      li {
        .help {
          height: 35px;
          padding: 10px 20px;
        } 
      }
    }
  }

  .options {
      list-style: none;
    li {
      position: relative;
      padding: 10px 20px;
      // padding-right: 55px;
      // padding-left: 55px;
      text-overflow: ellipsis;
      color: $color__copy--dark;

      &.question {
        background: $color__background;
        color: $color__copy--dark;

        font-size: $font-size__sub-header;
        font-family: FFMiloSerifWebText, serif;
        

        padding: 0;
        margin: 10px 20px;
        padding-bottom: 10px;

        border-bottom: 1px solid $color__border--light;

        white-space: normal;
        position: relative;
      }

      &.help {
        background: $color__background;
        color: $color__copy--dark;
        text-align: center;
        overflow: hidden;
        height: 0;
        padding: 0;

        span {
          display: block;
	        padding: 10px;
        }
      }

      .number {
        background-color: $color__red--barnes-dark;
        color: $color__background;
        font-family: Calibre, sans-serif;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-size: $font-size__body;
        
        width: 25px;
        height: 28px;
        
        margin-right: 6px;
        margin-top: 6px;
        float: left;
      }

      .procent {
        margin-top: 7px;
        float: right;
        font-size: $font-size__body;

        font-weight: 700;
      }

      .bar {
        background: $color__copy--light;
        height: 6px;
        margin-top: 2px;
        
        .bar-inner {
          background: $color__red--barnes-dark;
          height: 100%;
          width: 0;
          position: relative;

          transition: width 1s;
        }
      }

      .text {
        font-size: $font-size__body;
        line-height: 1.2;

        display: inline-block;
        max-width: 80%;

        min-height: 35px;
      }
    }
  }

  .border {
    border: 1px solid #fff;
  }

  .credits {
    display: none;
  }
}

// @see https://poll.ma.pe/themeTemplate.css
.custom.topLeft {
	top: 20px !important;
	left: 20px !important;
}
.custom.topCenter {
	top: 20px !important;
	left: 50% !important;
	transform: translate(-50%, 0) !important;
}
.custom.topRight {
	top: 20px !important;
	right: 20px !important;
}
.custom.middleLeft {
	top: 50% !important;
	transform: translate(0, -50%) !important;
	left: 20px !important;
}
.custom.middleCenter {
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
	left: 50% !important;
}
.custom.middleRight {
	top: 50% !important;
	transform: translate(0, -50%) !important;
	right: 20px !important;
}
.custom.bottomLeft {
	bottom: 20px !important;
	left: 20px !important;
}
.custom.bottomCenter {
	bottom: 20px !important;
	left: 50% !important;
	transform: translate(-50%, 0) !important;
}
.custom.bottomRight {
	bottom: 20px !important;
	right: 20px !important;
}