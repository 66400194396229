.console {
  padding: 0px 40px 30px;
  width: 100%;
  position: relative;

  &__header-section {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin: 0 0 5px;

    font-size: $font-size__header--desktop;
    line-height: 1.2;
  }

  &__subheader {
    margin: 0 0 5px;

    font-size: $font-size__sub-header;
    line-height: 1.2;
  }

  &__detail {
    margin: 10px 0;

    font-size: $font-size__body;
    line-height: 1.2;

    max-width: 380px;
  }

  &__button {
    max-width: 320px;

    &--sign-out {
      position: absolute;
      top: 0;
      right: 40px;
      max-width: 120px;
      height: 40px;
      line-height: 35px;
    }
  }

  &__content {
    margin-top: 30px;
  }

  &__channel {
    text-decoration: none;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    display: block;
    margin-top: 20px;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &:last-of-type {
      border: none;
    }

    table-layout: fixed;

    th,
    td {
      padding: 0 20px;
      width: calc(100% / 6);
    }

    tbody {
      & > tr {
        cursor: pointer;
      }
    }

    tr {
      font-size: $font-size__body;
      height: 55px;

      position: relative;
    }

    th {
      font-size: $font-size__sub-header;
      text-align: left;
    }
  }

  &__table-spinner {
    display: flex;
    align-items: center;
    height: 100px;

    & > div {
      // Opacity is transitioned using JS.
      transition: opacity 0.4s;

      margin: 0;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: italic;
    }
  }
}

// THEME
.console {
  $self: &;
  transition: color 0.4s, background-color 0.4s;

  @include theme() {
    color: getThemeValue('color__copy');
    background-color: getThemeValue('color__background--light');

    #{ $self } {
      &__button {
        background-color: getThemeValue('color__brand--medium');
        color: getThemeValue('color__background--light');
        border: 2px solid getThemeValue('color__brand--medium');
      }

      &__channel {
        color: getThemeColor('color__copy');
      }

      &__table {
        border-bottom: 1px solid getThemeValue('color__grey');

        th,
        td {
          border-left: 1px solid getThemeValue('color__copy--light');
          border-right: 1px solid getThemeValue('color__copy--light');
        }

        thead {
          & > tr {
            background-color: getThemeValue(
              'color__background--grey'
            );
          }
        }

        tbody {
          & > tr {
            &:hover {
              background-color: #{getThemeValue('color__nav--hover')}40;
            }
          }
        }

        tr {
          border: 1px solid getThemeValue('color__copy--light');
        }

        .a-brand-link {
          color: getThemeValue('color__brand--medium');
        }
      }

      &__table-spinner {
        & > div {
          font-size: $font-size__sub-header;
          color: getThemeValue('color__copy--light');
          border: 1px solid getThemeValue('color__copy--light');
          border-top: none;
        }
      }
    }
  }
}
