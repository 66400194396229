//////////////
/// COLORS ///
//////////////
$themes: (
  default: (
    color__copy: #000,
    color__contrast: #fff,
    color__grey: #dcdcdc,
    color__brand--light: #fa4c25,
    color__brand--medium: #d64220,
    color__brand--dark: #b3371b,
    color__copy--light: #8c8c8c,
    color__copy--medium: #6e6e6e,
    color__copy--dark: #282828,
    color__background--light: #fff,
    color__background--dark: #f7f7f7,
    color__background--grey: #dcdcdc,
    color__button: #f7f7f7,
    color__button--secondary: #fff,
    color__button--play: #d64220,
    color__button--border: #c6c2b9,
    color__border--dark: #b4b4b4,
    color__border--input: #c6c2b9,
    color__button--calendar: #f7f7f7,
    color__nav--hover: #e68e79,
  ),
  dark: (
    color__copy: #fff,
    color__contrast: #fff,
    color__grey: #fff,
    color__brand--light: #fff,
    color__brand--medium: #fff,
    color__brand--dark: #fff,
    color__copy--light: #fff,
    color__copy--medium: #fff,
    color__copy--dark: #fff,
    color__background--light: #171717,
    color__background--dark: #000,
    color__background--grey: #000,
    color__button: #f7f7f7,
    color__button--secondary: #006cff,
    color__button--play: #000,
    color__button--border: #c6c2b9,
    color__border--dark: #b4b4b4,
    color__border--input: #fff,
    color__button--calendar: #000,
    color__nav--hover: #f7f7f7,
  ),
);

// $color__contrast: "color__contrast";
// $color__grey: "color__grey";

// $color__brand--light: "color__brand--light";
// $color__brand--medium: "color__brand--medium";
// $color__brand--dark: "color__brand--dark";

// $color__copy--light: "color__copy--light";
// $color__copy--medium: "color__copy--medium";
// $color__copy--dark: "color__copy--dark";

// $color__background--light: "color__background--light";
// $color__background--dark: "color__background--dark";

// $color__button: "color__button";
// $color__button--border: "color__button--border";
// $color__border--dark: "color__border--dark";

// $color__nav--hover: "color__nav--hover";

// added per secondary palette in Barnes Branding Guidelines Document
$color__admin-blue: #004c97;
$color__admin-blue--light: #6cace4;
$color__green: #8e9a36;
$color__yellow: #fdd824;

$color__contrast: #fff;
$color__grey: #dcdcdc;

$color__brand--light: #d64220; // Updated per design 9/27
$color__brand--medium: #d64220;
$color__brand--dark: #b3371b;

$color__copy: #000;
$color__copy--light: #8c8c8c;
$color__copy--medium: #6e6e6e;
$color__copy--dark: #282828;

$color__background--light: #fff;
$color__background--dark: #f7f7f7;
$color__background--grey: #dcdcdc;

$color__button: $color__background--dark;
$color__button--border: #c6c2b9;
$color__button--calendar: $color__background--dark;
$color__border--dark: #b4b4b4;

$color__nav--hover: #e68e79;

/////////////
/// FONTS ///
/////////////
$font-family__primary: Calibre, sans-serif;
$font-family__secondary: FFMiloSerifWebText, serif;

/////////////////
/// FONT SIZE ///
/////////////////
$font-size__massive--mobile: 40px;
$font-size__massive--desktop: 42px;
$font-size__header--mobile: 32px;
$font-size__header--desktop: 38px;
$font-size__sub-header: 25px;
$font-size__button: 19px;
$font-size__body: 18px;
$font-size__chat: 18px;

///////////////
// Z-INDEXES //
///////////////
$z-indexes: (
  content: 1,
  button: 2,
  overlay: 3,
  chat: 5,
  modal-overlay: 7,
  modal: 8,
  userInfo: 9,
  welcomeMessage: 10,
);
