//////////////////////////////
// GENERAL GLOBAL SELECTORS //
//////////////////////////////
* {
    box-sizing: border-box;
}

body {
    // CRA boiler plate.
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: $font-family__primary;
    font-size: $font-size__body;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

////////////
// INPUTS //
////////////
input {
    &[type="text"] {
        font-family: $font-family__primary;
        font-size: $font-size__body;
        height: 45px;
        padding: 10px 15px;
    }
}

textarea {
    font-family: $font-family__primary;
    font-size: $font-size__body;
    height: 45px;
    padding: 10px 15px;
}

///////////////
// CONTAINER //
///////////////
.container {
    @include breakpoint("xlarge+") {
        width: 1090px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    }
}

///////////////
/// BUTTONS ///
///////////////
.btn-barnes {
    width: 100%;
    line-height: 54px;

    font-family: $font-family__primary;
    font-weight: 700;
    font-size: 19px;

    background-color: $color__brand--medium;
    color: $color__contrast;
    border: 2px solid $color__brand--medium;
    border-radius: 0;
    
    cursor: pointer;
    display: inline-block;
    height: 60px;
    padding: 0 30px;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease .1s;
    white-space: nowrap;
    width: 100%;

    margin-top: 10px;
    margin-bottom: 20px;

    &:active,
    &:hover {
        border-color: $color__brand--dark;
        background-color: $color__brand--dark;
    }

    transition: color 0.5s, background-color 0.5s, border-color 0.5s;

    &--disabled {
        color: $color__copy--medium;
        border-color: $color__grey;
        background-color: $color__grey;

        &:active,
        &:hover {
            cursor: default;
            color: $color__copy--medium;
            border-color: $color__grey;
            background-color: $color__grey;
        }
    }
}

/////////////
/// LINKS ///
/////////////
a {
    color: $color__copy--light;
    text-decoration: underline;

    &:hover {
        color: $color__copy--dark;
    }
}

.a-brand-link {
    color: $color__brand--medium;
    text-decoration: none;

    &:visited {
        color: mix(#000, $color__brand--medium, 10%);
    }

    &:hover,
    &:active {
        color: $color__brand--dark;
    }

    &:focus:not(.focus-ring) {
        outline: none;
        text-decoration: underline;
    }

    &--no-underline {
        text-decoration: none !important;

        &:active,
        &:visited {
            text-decoration: none !important;
        }
    }
}
