/**
* Calculate modifier provided to theme
*/
@function getModifier($themeName) {
    @if $themeName == "default" {
        @return "";
    } @else {
        @return "--#{$themeName}";    
    }
}

/**
* Mixin to use to generate blocks for each theme
* Automatically takes @content
*/
$scopedTheme: null;
@mixin theme($allThemesMap: $themes) {
    @each $themeName, $themeMap in $allThemesMap {
        &#{getModifier($themeName)} {
            // Creating a map that contains values specific to theme.
            // Global is necessary since in mixin
            $scopedTheme: () !global;
            @each $variableName, $variableValue in $themeMap {
                // Merge each key-value pair into the theme specific map
                $scopedTheme: map-merge($scopedTheme, ($variableName: $variableValue)) !global;
            }
            // The original content passed
            @content;
            // Unset
            $scopedTheme: null !global;
        }
    }
}
/**
* Function to call within theme mixin, to get value from the current theme in the iterator
*/
@function getThemeValue($themeVar){
    @return map-get($scopedTheme, $themeVar);
}

/**
* EXAMPLE USAGE:
* Actually using theme values to generate CSS

.myComponent {
    font-size: 16px;
    
    @include theme() {
        color: getThemeValue('secondary');
    }
    
    &__thing {
        margin: 10px;
        
        @include theme() {
            background-color: getThemeValue('primary');
            color: getThemeValue('secondary');
        }
    }
    
}

*/