$toggle-width: 40px;

.toggle {
    position: relative;
    margin-left: calc(#{ $toggle-width } - 10px);
    margin-right: 30px;
    margin-bottom: 15px;
    
    height: 0;
    width: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    

    &::before {
        content: ' ';
        display: block;

        position: absolute;
        top: -6px;
        right: -22px;

        width: $toggle-width;
        height: 19px;

        transition: background-color 0.2s ease, border-color 0.2s ease;
        border: 2px solid $color__button--border;
        border-radius: 12px;
    }

    &::after {
        content: ' ';
        position: absolute;
        top: -4px;

        cursor: pointer;
        touch-action: pan-x;

        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 2px solid $color__copy--medium;
        background-color: #fff;

        transition: transform 0.2s ease, border-color 0.2s ease;
        transform: translate3d(1px,0,0);
    }

    &--active {
        &::after {
            transform: translate3d(calc((#{ $toggle-width } - 20px) * -1), 0, 0);
            border-color: $color__button--border; // $color__red--barnes-dark;
        }

        &::before {
            background-color: #{$color__brand--dark}af;
            border-color: $color__button--border; // $color__red--barnes-dark;
        }
    }
}