/**
* Include chatDown scss and then override.
* @see https://www.npmjs.com/package/stream-chat-react
*/
@import 'node_modules/stream-chat-react/dist/scss/_variables.scss';
@import 'node_modules/stream-chat-react/dist/scss/index.scss';

.chat {
  $self: &;

  height: 100%;
  position: relative;

  z-index: map-get($z-indexes, 'chat');

  @include breakpoint('small-') {
    max-height: 450px;
  }

  &__spinner {
    position: absolute;
    top: 40%;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__user-info {
    position: absolute;
    top: 120px;
    width: 90%;
    left: 5%;

    z-index: map-get($z-indexes, 'userInfo');
  }

  &__welcome-message {
    position: absolute;
    bottom: 120px; // change the positioning of the welcome message to cover the chat input
    width: 90%; // change width to cover chat input
    left: 5%;

    z-index: map-get($z-indexes, 'welcomeMessage');
  }

  &__cooldown-text {
    position: absolute;
    top: 0;
    left: 0;

    color: #fff;

    display: block;
    text-align: center;
    line-height: 16px;
    opacity: 0;
    z-index: 2;
    width: 100%;
    font-size: 12px;
  }

  &__cooldown-bar {
    position: absolute;
    top: 0;
    left: 0;

    width: 0%;
    height: 100%;

    background-color: $color__admin-blue;

    &::after {
      display: block;
      content: ' ';
    }
  }

  &__cooldown {
    position: relative;

    transition: background-color 0.4s;

    height: 16px;

    &--active {
      #{$self} {
        &__cooldown-bar {
          transition: width 10s;
          width: 100%;
          opacity: 1;
          z-index: 1;
        }

        &__cooldown-text {
          opacity: 1;
        }
      }
    }
  }

  &__message-list,
  &__participant-list {
    flex: 1 1 0;
    overflow-y: scroll;

    display: flex;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__message-list {
    flex-direction: column-reverse;
    padding: 75px 15px 15px;
  }

  &__participant-list {
    flex-direction: column;
    padding: 15px;
  }

  &__message {
    line-height: 1.3;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    &--admin {
      #{ $self } {
        &__username {
          color: $color__admin-blue;
        }
      }
    }

    &:hover {
      & #{ $self } {
        &__reaction-wrapper {
          margin: 0 5px 0 0;
          padding-left: 5px;
        }

        &__reaction {
          display: inline-block;
        }
      }
    }
  }

  &__username {
    font-weight: 700;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    &:hover {
      text-decoration: underline;
    }
  }

  &__delete {
    background: transparent;
    border: 1px solid $color__admin-blue;
    color: inherit;

    margin-right: 5px;

    &:hover {
      border: 2px solid $color__admin-blue;
      cursor: pointer;
      color: $color__copy !important;
    }
  }

  &__muted {
    color: $color__brand--medium;
    padding: 1px;
    margin-right: 5px;
  }

  &__lock {
    position: absolute;
    top: 185px;
    right: 15px;
    height: 70px;
    width: 70px;

    border-radius: 50%;
    border: 1px solid $color__admin-blue;

    text-transform: uppercase;
    letter-spacing: 1.1px;

    cursor: pointer;

    -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.28);

    transition: transform 0.4s, background-color 0.4s;

    z-index: 9;

    &:hover {
      transform: translate3d(-1px, -1px, 0) scale(1.02);
    }
  }

  &__reaction-wrapper {
    background-color: $color__background--dark;
    border-radius: 20px;
    line-height: 20px;
    // min-width: 120px;

    // position: absolute;
    // top: -10px;
    // left: 0;

    display: inline-block;

    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;

    & > #{ $self } {
      &__reaction {
        display: none;
      }
    }

    &--active {
      margin: 0 5px 0 0;
      padding-left: 5px;

      & > #{ $self } {
        &__reaction {
          &--active {
            display: inline-block;
          }
        }
      }
    }
  }

  &__reaction {
    background-color: transparent;
    border: none;
    padding: 0 5px 0 0;
    color: transparent;
    text-shadow: 0 0 0 darken($color__background--dark, 15);
    cursor: pointer;

    & > span {
      font-size: 10px !important;
      margin-left: 2px;
    }

    &--active,
    &:hover {
      color: initial;
    }
  }

  &__user-avatar-wrapper {
    display: inline-flex;
    overflow: hidden;
    width: 16px;
    height: 16px;

    margin: 0 5px 0 2px;
    border-radius: 2px;
  }

  &__user-avatar {
    width: 22px;
    overflow: hidden;
    min-height: 100%;
  }

  &__custom-emoji {
    width: 32px;
    margin: 2px 2px -5px 2px;
    border-radius: 2px;
  }

  &--admin {
    border-left: 5px double $color__admin-blue;
    border-top: 5px double $color__admin-blue;

    .str-chat {
      &__message-simple {
        &__actions {
          position: relative;
          display: flex !important;
        }
      }
      & .chat__message {
        padding: 4px;
        &:hover {
          border: 2px solid $color__admin-blue--light;
        }
      }
    }
  }

  &--user {
    .str-chat {
      &__message-simple {
        &__actions {
          position: relative;
          display: flex !important;
          min-width: 30px;

          &__action {
            &--options,
            &--thread {
              flex: 0 0 0;
              display: none;
              margin: 0;

              & > svg {
                width: 0 !important;
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__option-tabs {
    display: flex;
    direction: row;

    .btn-barnes {
      margin: 8px;
      padding: 0 15px;

      &.btn-secondary {
        border: 2px solid;
      }
    }

    &.option-tabs--secondary {
      margin: 0 4px;

      .btn-barnes {
        padding: 0 5px;
        margin: 4px;
        height: 40px;
        line-height: unset;
      }
    }
  }
}

.str-chat {
  $self: &;

  height: 100%;

  & * {
    font-family: $font-family__primary !important;
    font-size: $font-size__chat !important;
  }

  .chat__reaction {
    font-size: 12px !important;
  }

  & .chat__message {
    font-size: $font-size__body !important;
    padding: 1px 0;
    &:hover {
      cursor: default;
    }
  }

  &__main-panel {
    padding: 0 0 0 1px !important;
  }

  &__header-livestream {
    border-radius: 0 !important;
    box-shadow: none !important;

    background: url('https://barnes-twitch-assets.s3.amazonaws.com/soutine.jpg') !important;

    @include breakpoint('small-') {
      display: none !important;
    }

    @include breakpoint('small+') {
      height: 100px !important;
    }

    #{ $self } {
      &__avatar {
        display: none;
      }
    }

    &-left {
      font-size: $font-size__sub-header;

      &--members {
        display: none;
      }

      &--title {
        font-size: $font-size__header--desktop !important;
        text-align: center;
      }
    }
  }

  &__header-hamburger {
    display: none !important;
  }

  &__textarea {
    textarea {
      border-radius: 0 !important;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__message-simple-text-inner {
    max-width: 220px !important;

    &--is-emoji {
      border: 1px solid rgba(0, 0, 0, 0.08);
      font-size: $font-size__sub-header !important;
      padding: 5px 10px;

      & > * {
        font-size: $font-size__sub-header !important;
      }
    }
  }

  &__message-simple-data {
    max-width: 220px !important;
  }

  &__message-simple {
    &__actions {
      position: absolute;
      display: none !important;

      &__action {
        flex: 0 0 10px;

        &--thread {
          flex: 0 0 0;
          display: none;
          margin: 0;

          & > svg {
            width: 0 !important;
            display: none;
          }
        }
      }
    }
  }

  &__message-simple-status {
    bottom: 32px !important;
    left: calc(100% - 10px) !important;

    @include breakpoint('large+') {
      bottom: 30px !important;
      left: calc(100% - 30px) !important;
    }

    & svg {
      overflow: visible;
    }
  }

  &__message-inner {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  &__date-separator {
    display: none;
  }

  &__message-attachment {
    display: none !important;
  }

  &__message {
    &--failed,
    &--deleted {
      display: none;
    }
  }

  &__input {
    .rfu-file-upload-button {
      display: none;
    }
  }

  &__message-reactions-list-item {
    &[data-text='sad'],
    &[data-text='angry'],
    &[data-text='love'] {
      display: none;
    }
  }

  &__typing-indicator {
    display: none;
  }

  // &__messages-actions-list {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  // }
}

/// THEME
.chat {
  $self: &;

  @include theme() {
    background-color: getThemeValue(
      'color__background--grey'
    ) !important;
    transition: background-color 0.4s;

    @include breakpoint('small-') {
      border-bottom: 1px solid getThemeValue('color__grey');
    }

    #{ $self } {
      &__message-list,
      &__participant-list {
        background-color: getThemeValue(
          'color__background--light'
        ) !important;
        transition: background-color 0.4s;
      }

      &__reaction-wrapper {
        background-color: getThemeValue('color__background--dark');
      }

      &__reaction {
        &--active,
        &:hover {
          color: getThemeValue('color__copy--dark');
        }
      }
      &__input--message {
        font-size: $font-size__body;
        font-weight: bold;
        margin: 24px;
      }
    }

    & .str-chat {
      & .chat__message {
        &:hover {
          background-color: getThemeValue(
            'color__background--dark'
          ) !important;
        }
      }

      &__main-panel {
        @include breakpoint('small-') {
          border-top: 3px double getThemeValue('color__grey') !important;
        }
      }

      &__message-text-inner {
        color: getThemeValue('color__copy--dark') !important;
      }

      &__header-livestream {
        border-bottom: 1px solid
          getThemeValue('color__background--grey') !important;

        &-left {
          &--title {
            color: getThemeValue('color__contrast') !important;
          }
        }
      }

      &__textarea {
        textarea {
          border-color: getThemeValue(
            'color__border--input'
          ) !important;

          &::placeholder {
            color: getThemeValue('color__copy--light') !important;
          }
        }
      }

      // Hiding this for now.
      // &__date-separator-line {
      //     background-color: getThemeValue("color__grey") !important;
      // }

      // &__date-separator-date {
      //     color: getThemeValue("color__copy--medium") !important;
      // }

      &__message-simple-timestamp {
        color: getThemeValue('color__copy--light') !important;
      }

      &__message-text-inner {
        background: getThemeValue(
          'color__background--dark'
        ) !important;
      }

      &__message-simple-status {
        & path {
          fill: getThemeValue('color__brand--light');
        }
      }

      &__input-footer--count {
        color: getThemeValue('color__copy--medium');
      }
    }
  }
}
