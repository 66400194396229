.log-in {
	width: 100%;
	height: 100%;

	font-family: Calibre,sans-serif;

	display: flex;
	justify-content: center;
	align-items: center;

	&__form {
		display: flex;
		flex-direction: column;

		width: 40%;
		max-width: 380px;

		padding: 20px 40px 15%;
	}

	&__header {
		width: 100%;
        
        text-align: center;
        font-size: $font-size__massive--desktop;

		margin-bottom: 0px;

		&--sub {
            font-weight: 200;
            
            font-size: $font-size__sub-header;

			margin-top: 10px;
			margin-bottom: 50px;
		}
	}

	&__button-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;

		.abcRioButtonBlue {
			background-color: $color__brand--medium;

			&:active,
			&:hover {
				background-color: $color__brand--dark;
				outline-color: $color__brand--dark;
				outline-color: $color__brand--dark;
			}
		}
	}

	&__error {
		text-align: center;
        font-size: $font-size__sub-header;
		
		color: $color__brand--light;
        
		margin: 30px 0 10px 0;

		& > a {
			color: $color__brand--light;
		}
	}

	&__flex-wrapper {
		display: flex;
		justify-content: center;
	}

	&__select {
		font-family: $font-family__primary;
		font-size: $font-size__body;
		padding: 10px;
		height: 55px;
		min-width: 400px;
	}
}

// THEME
.log-in {
    $self: &;

    @include theme() {
        #{ $self } {
            &__header {
                color: getThemeValue("color__copy");
			}
			
			&__select {
				color: getThemeValue("color__copy");
				background-color: getThemeValue("color__background--light");
			}
        }
    }
}