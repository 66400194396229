.nav {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 70px;

    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint("medium+") {
        height: 100px;

        width: auto;
        padding-right: 40px;
        padding-left: 40px;
    }

    &__logo {
        height: 37px;

        @include breakpoint("medium+") {
            height: 45px;
        }

        @include breakpoint("large+") {
            height: 50px;
        }

        &.centennial-logo {
            height: 40px;

            @include breakpoint("medium+") {
                height: 48px;
            }
    
            @include breakpoint("large+") {
                height: 54px;
            }
        }
    }

    &__theme-toggle {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

// THEME
.nav {
    $self: &;

    @include theme() {
        transition: color 0.4s, background-color 0.4s;

        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");

        #{ $self } {
            &__logo {
                transition: color 0.4s;
                color: getThemeValue("color__brand--light");
            }
        }
    }
}