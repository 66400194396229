.user-info {
  border-radius: 20px;

  display: flex;
  padding: 40px 20px 30px;

  -webkit-box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);

  opacity: 1;
  transition: opacity 0.4s;

  &--hidden {
    // opacity: 0;
    display: none;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    border-radius: 50%;
    width: 75px;
    height: 75px;

    margin-right: 20px;
  }

  &__description {
    display: flex;
    flex-direction: column;

    & > h4 {
      font-size: $font-size__body;
      margin: 0 0 10px;
    }

    & > p {
      font-family: $font-family__secondary;
      font-size: $font-size__chat;
      margin: 0;
    }
  }

  &__close {
    border: none;
    background-color: transparent;

    position: absolute;
    top: 15px;
    right: 15px;

    cursor: pointer;

    & > svg {
      width: 10px;
      height: 10px;
    }
  }

  &__link {
    text-decoration: underline;
  }
}

// THEME
.user-info {
  $self: &;

  @include theme() {
    color: getThemeValue('color__copy');
    background-color: getThemeValue('color__background--grey');

    #{ $self } {
      &__close {
        & > svg {
          color: getThemeValue('color__copy');
        }
      }
    }
  }
}
