.back-link {
    border: none;
    background-color: transparent;
    cursor: pointer;

    font-size: $font-size__body;

    color: $color__copy--light;
    text-decoration: underline;

    &:hover {
        color: $color__copy--dark;
    }

    margin-bottom: 10px;
}