.admin {
    &__content {
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 100px);

        &--padding-bottom {
            padding-bottom: 100px;
        }
    }
}

// THEME
.admin {
    transition: color 0.4s, background-color 0.4s;

    @include theme() {
        color: getThemeValue("color__copy");
        background-color: getThemeValue("color__background--light");
    }
}