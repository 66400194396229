.welcome-message {
  border-radius: 20px;
  bottom: 120px;
  left: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 30px;
  align-items: center;

  -webkit-box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 43px -33px rgba(0, 0, 0, 0.75);

  opacity: 1;
  transition: opacity 0.4s;

  &--hidden {
    // opacity: 0;
    display: none;
  }

  &__description {
    display: flex;
    flex-direction: column;

    & > h4 {
      font-size: $font-size__body;
      margin: 8px;
    }

    & > p {
      font-family: $font-family__secondary;
      font-size: $font-size__chat;
      margin: 8px;
    }
  }

  &__ok {
    width: 50%;
    padding: 0;
    margin: 8px;
  }

  // &__close {
  //   border: none;
  //   background-color: transparent;
  //   position: absolute;
  //   top: 15px;
  //   right: 15px;

  //   cursor: pointer;

  //   & > svg {
  //     width: 10px;
  //     height: 10px;
  //   }
  // }
}

// THEME
.welcome-message {
  $self: &;

  @include theme() {
    color: getThemeValue('color__copy');
    background-color: getThemeValue('color__background--grey');

    #{ $self } {
      &__close {
        & > svg {
          color: getThemeValue('color__copy');
        }
      }
    }
  }
}
