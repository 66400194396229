$visual-elements: 'Textures', 'Lines', 'Brushstrokes', 'Space',
  'Balance', 'Proportion';

.emoji-mart-scroll {
  display: grid;
}

.emoji-mart-category {
  order: length($visual-elements) + 1;

  @for $i from 1 through length($visual-elements) {
    $visual-element: nth($visual-elements, $i);

    &[aria-label='#{ $visual-element }'] {
      order: $i;
    }
  }
}

.emoji-mart-category-label {
  span {
    font-size: 18px !important;
  }
}

.emoji-mart-title-label {
  font-size: 18px !important;
}
.emoji-mart-emoji-custom {
  height: 60px;
  width: 60px;
  span {
    height: 56px !important;
    width: 56px !important;
  }
}

.emoji-mart-preview {
  height: 50px !important;
}
