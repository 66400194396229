.footer {
    /////////////
    // SPONSOR //
    /////////////
    &__sponsor {
        padding: 58px 40px 50px;
    }

    &__sponsor-container {
        display: flex;
        flex-direction: row;
    }

    &__sponsor-column {
        flex: 0 0 calc(50% - 15px);
        
        &:first-of-type {
            margin-right: 20px;
        }
    }

    &__sponsor-copy {
        margin: 0;
        line-height: 30px;
    }

    &__sponsor-links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        margin-top: 20px;
        line-height: 30px;

        @include breakpoint('large+') {
            flex-direction: row;
            margin-top: 0;
        }
    }

    &__sponsor-link {
        margin-right: 30px;
    }


    /////////////
    // SUPPORT //
    /////////////
    &__support {
        padding: 58px 40px 50px;

        @include breakpoint("xlarge+") {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__support-header-section {
        max-width: 380px;
    }

    &__support-header {
        line-height: 1.2;
        font-size: $font-size__sub-header;
        font-weight: bold;
    }

    &__support-buttons {
        width: 100%;
    }

    &__support-button {
        font-family: Calibre,sans-serif;
        font-weight: bold;
        font-size: $font-size__button;
        
        background-color: transparent;
        border-radius: 0;
        
        display: inline-block;

        height: 60px;
        line-height: 57px;
        min-width: 110px;

        padding: 0 30px;
        margin: 17px 0 0 15px;

        text-align: center;
        text-decoration: none;

        transition: all .25s ease .1s;
        white-space: nowrap;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:first-of-type {
            margin-left: 0;
        }
    }

    /////////////
    // ADDRESS //
    /////////////
    &__address-section {
        display: flex;
        flex-direction: row;
        margin-top: 35px;

        padding-bottom: 30px;
    }

    &__address-header {
        @include zeta();
        font-size: 15px;
        margin: 0;
    }

    &__address {
        font-style: inherit;
        line-height: 30px;
        font-size: $font-size__button;
        
        margin-top: 15px;

        &:first-of-type {
            margin-right: 30px;
        }

        & > a {
            display: block;
        }
    }

    ///////////////
    // AUXILIARY //
    ///////////////
    $self: &;
    &__auxiliary {
        padding-top: 38px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin: 0 -10px 30px;

        &--social {
            padding: 0;
            margin: 0 -15px 0;

            #{ $self } {
                &__auxiliary-link {
                    margin: 0 15px;
                }
            }

            & svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__auxiliary-link {
        margin: 0 10px 5px;
        white-space: nowrap;
        text-decoration: none;
        font-size: $font-size__button;
    }
}

// THEME
.footer {
    $self: &;

    &--dark {
        & .a-brand-link {
            text-decoration: underline;
        }
    }

    @include theme() {
        & .a-brand-link {
            color: getThemeValue("color__brand--medium");

            &:visited {
                color: mix(#000, getThemeValue("color__brand--medium"), 10%);
            }

            &:hover,
            &:active {
                color: getThemeValue("color__brand--dark");
            }
        }

        #{ $self } {
            &__sponsor {
                background-color: getThemeValue("color__background--dark");
            }

            &__sponsor-copy {
                color: getThemeValue("color__copy--medium");
            }

            &__support-header {
                color: getThemeValue("color__copy--dark");
            }

            &__support-button {
                color: getThemeValue("color__copy--medium");
                border: 2px solid getThemeValue("color__grey");

                &:hover {
                    border-color: getThemeValue("color__border--dark");
                }
            }

            &__address-section {
                border-bottom: 1px solid getThemeValue("color__grey");
            }

            &__address-header {
                color: getThemeValue("color__copy--dark");
            }

            &__address {
                color: getThemeValue("color__copy--medium");
            }

            &__auxiliary {
                & > a {
                    color: getThemeValue("color__copy--light");

                    &:hover {
                        color: getThemeValue("color__copy--dark");
                    }
                }
            }
        }
    }
}