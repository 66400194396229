// @import "node_modules/react-day-picker/lib/style.css";

@mixin day-label($content) {
  &::before {
    content: $content;
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 8px;
  }
}

.create-channel {
  padding: 0px 40px 30px;
  width: 100%;

  &--ask {
    padding: 0;
    margin-top: 20px;
  }

  &__header-section {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin: 0 0 5px;

    font-size: $font-size__header--desktop;
    line-height: 1.2;
  }

  &__detail {
    margin: 10px 0;

    font-size: $font-size__body;
    line-height: 1.2;

    max-width: 380px;
  }

  &__form {
    width: 50%;
    padding-bottom: 60px;
  }

  &__form-block {
    display: flex;
    flex-direction: column;

    &--row {
      flex: 1;
      flex-direction: row;
      align-items: center;
    }
  }

  &__form-label-text {
    display: inline-block;
    font-size: $font-size__sub-header;
    line-height: calc(#{$font-size__sub-header} + 10px);
    margin-bottom: 5px;
    margin-top: 30px;
  }

  &__form-flex-row {
    display: flex;
    flex-direction: row;
    margin-left: -10px;

    & > * {
      flex: 1;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &__form-input {
    &--text-area {
      min-height: 200px;
    }

    &--select {
      padding: 10px;
      height: 55px;
      font-size: 18px;
    }

    &--question {
      margin-bottom: 10px;
      width: 100%;
    }

    &--option {
      margin-bottom: 5px;
      width: 100%;
    }

    &:disabled {
      font-style: italic;
    }

    &--number {
      height: 45px;
      padding: 10px 15px;
    }
  }

  &__form-toggle-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__form-submit-section {
    margin-top: 30px;

    display: flex;
    flex-direction: row;
  }

  &__form-submit-button {
    transition: color 0.5s, background-color 0.5s, border-color 0.5s;

    font-family: $font-family__primary;

    font-weight: 700;
    font-size: 19px;
    width: 100%;

    max-width: fit-content;
    display: block;

    margin: 0 0 0 30px;

    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    &--ask {
      margin-bottom: 10px;
      margin-left: 20px;
      line-height: 42px;
      height: 45px;

      &:first-of-type {
        margin-left: 20px;
      }
    }

    &--disabled {
      font-style: italic;
      cursor: not-allowed;
    }

    &--margin-top {
      margin-top: 20px;
    }

    &--margin-left {
      margin-left: auto !important;
    }
  }

  &__form-error {
    margin-top: 10px;
    display: block;
  }

  &__form-poll-questions {
    margin-top: 10px;

    padding: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__form-option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__form-plus {
    justify-self: flex-end;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0;
    display: block;
    border-radius: 50%;
    margin-left: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    line-height: initial;
  }

  &__form-number {
    width: 30px;
  }

  &__spinner {
    margin: 0 !important;

    div {
      background-color: #fff !important;
    }
  }

  ////////////////////////////////////
  // Styling for react-time-picker. //
  ////////////////////////////////////
  &__form-time-picker {
    max-width: 200px;
    select {
      option {
        color: black !important;
      }
    }
  }

  .react-time-picker {
    &__wrapper {
      font-size: 22px;
      height: 45px;
      padding: 0 15px;
    }

    &__clear-button {
      display: none;
    }
  }

  ///////////////////////////////////
  // Styling for react-day-picker. //
  ///////////////////////////////////
  .DayPicker {
    width: 100%;
    max-width: 380px;
  }

  .DayPicker-Caption {
    text-align: center;
    line-height: 1.42857143;

    font-size: $font-size__chat;
  }

  .DayPicker-Month {
    width: 100%;
    border-collapse: separate;
    border-spacing: 3px 3px;

    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }

  .DayPicker-NavButton {
    top: 12px;
    right: 0px;
  }

  .DayPicker-Day {
    padding: 10px 0;
    border-radius: 0;
    font-size: 171%;
    margin: 5px;

    line-height: 1.42857143;

    position: relative;

    &--disabled {
      opacity: 0.5;

      @include day-label('No Events');
    }

    &--today {
      font-weight: initial;

      @include day-label('Today');
    }

    &--outside {
      background-color: transparent !important;
      border: none !important;

      &::before {
        display: none;
      }
    }
  }
}

// THEME
.create-channel {
  $self: &;
  transition: color 0.4s, background-color 0.4s;

  @include theme() {
    color: getThemeValue('color__copy');
    background-color: getThemeValue('color__background--light');

    #{ $self } {
      &__form-input {
        color: getThemeValue('color__copy');
        background-color: getThemeValue('color__background--light');
        border: 1px solid getThemeValue('color__button--border');

        &:disabled {
          background-color: getThemeColor(
            'color__background--grey'
          ) !important;
          color: $color__copy--medium !important;
        }
      }

      &__form-error {
        color: getThemeValue('color__brand--light');
      }

      &__form-plus,
      &__form-submit-button {
        background-color: getThemeValue('color__brand--medium');
        color: getThemeValue('color__background--light');
        border: 2px solid getThemeValue('color__brand--medium');

        &:hover {
          border-color: getThemeValue('color__brand--dark');
          background-color: getThemeValue('color__brand--dark');
        }

        &--disabled,
        &--delete {
          color: getThemeValue('color__copy--medium');
          background-color: getThemeValue('color__background--light');
          border: 2px solid getThemeValue('color__grey');

          &:hover {
            color: getThemeValue('color__copy--medium');
            background-color: getThemeValue(
              'color__background--light'
            );
            border-color: getThemeValue('color__border--dark');
          }

          #{ $self }__spinner {
            margin: 0 !important;

            div {
              background-color: getThemeValue(
                'color__grey'
              ) !important;
            }
          }
        }
      }

      &__form-poll-questions {
        border: 1px solid getThemeValue('color__grey');
        background-color: getThemeValue('color__background--grey');

        &--ask {
          border: none;
          background-color: transparent;
        }
      }
    }

    & .DayPicker-Week {
      & > DayPicker-Day {
        &:hover {
          background-color: getThemeValue(
            'color__button--calendar'
          ) !important;
        }
      }
    }

    & .DayPicker-Day {
      color: getThemeValue('color__copy');
      background-color: getThemeValue('color__button--calendar');
      border: 1px solid getThemeValue('color__button--border');

      &:hover {
        background-color: getThemeValue(
          'color__button--calendar'
        ) !important;
      }

      &--selected {
        color: getThemeValue('color__background--light') !important;

        border-color: getThemeValue('color__brand--dark');
        background-color: getThemeValue(
          'color__brand--medium'
        ) !important;

        &:hover {
          border-color: getThemeValue('color__brand--dark');
          background-color: getThemeValue(
            'color__brand--medium'
          ) !important;
        }
      }
    }

    & .react-time-picker {
      &__wrapper {
        border: 1px solid getThemeValue('color__button--border');
      }

      &__inputGroup {
        &__input {
          color: getThemeValue('color__copy');
          // background-color: getThemeValue('color__background--light');
        }
      }
    }
  }
}
