.warning-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 140px;

    .btn-barnes {
        max-width: max-content;
    }
}

.warning {
    margin: 20px 0 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
	
	&__icon {
		width: 140px;
        display: block;
        
	}

	&__text {
		margin-top: 30px;
		font-size: 18px;
	}
}

// THEME
.warning {
    $self: &;

    @include theme() {
        #{ $self } {
            &__icon,
            &__text {
                color: getThemeValue("color__copy--medium");
            }
        }
    }
}