$video-z-indexes: (
    video: 1,
    button: 2,
    overlay: 3,
    poster: 4,
);

.video-wrapper {
    position: relative;

    & > .video-js, .video {
        background-color:#000;
        height: 100%;
        width: 100%;

        @include breakpoint("medium+") {
            min-height: 300px;
        }

        @include breakpoint("large+") {
            min-height: 400px;
        }
    }
}

.video {

    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        z-index: -1;
        opacity: 0;
        transition: opacity 1s;

        &--active {
            z-index: map-get($video-z-indexes, "poster");
            opacity: 1;
        }
    }

    &__opacity-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        cursor: pointer;

        background-color: rgba(0, 0, 0, 0);
        transition: background-color 1s, z-index 0s 0.4s;

        // Remove button styling
        border: none;
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-bottom: 30px;

        &--active {
            background-color: rgba(0, 0, 0, 0.7);
            z-index: map-get($video-z-indexes, "overlay");
        }
    }

    &__opacity-overlay-logo {
        display: block;

        width: 240px;
        color: #fff;
        opacity: 1;
        margin-bottom: 20px;
    }

    &__opacity-overlay-message {
        display: block;
        color: #fff;

        font-family: $font-family__primary;
        font-size: $font-size__sub-header;
        font-weight: 500;
    }

    &__unmute-button-wrapper {
        position: absolute;
        top: 40px;
        left: 50%;

        z-index: map-get($video-z-indexes, "button");

        transition: opacity 0.4s, transform 0.4s;

        &--hidden {
            opacity: 0;
            transform: translateY(-100%)
        }
    }
    
    &__unmute-button {
        position: relative;
        left: -50%;

        font-size: $font-size__chat;
        height: 40px;
        line-height: 35px;
    }
}

// VIDEO JS
.video-js {
    z-index: map-get($video-z-indexes, "video");
}

.vjs-big-play-button {
    display: none !important;
}

.vjs-control-bar {
    // opacity: 1 !important;
    background-color:#000 !important;
}

.vjs-tech {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
}

// THEME
.video-wrapper {
    @include theme() {
        & .vjs-big-play-button {
            background-color: getThemeValue("color__button--play") !important;
        }
    }
}